import React, { memo, useEffect, useState } from "react";
import styles from "./breakings.module.less";
import { Col, Row, Collapse } from "antd";
import { BreakingModel } from "./breakings.model";
import { useResize } from "../app/resize.hook";

interface BreakingItemProps {
  readonly breaking: BreakingModel;
  readonly setModalVisible: () => void;
}

export const BreakingItem = memo<BreakingItemProps>(
  ({ breaking, setModalVisible }) => {
    const width = useResize();

    return (
      <>
        {width >= 768 && (
          <div className={styles.breaking__item}>
            <Row
              justify={"start"}
              className={styles.breaking__row}
              gutter={[
                { xs: 0, sm: 0, md: 8, lg: 8 },
                { xs: 0, sm: 0, md: 10, lg: 10 },
              ]}
            >
              <Col span={8} className={styles.breaking__column}>
                {breaking.symptom}
              </Col>
              <Col span={8} className={styles.breaking__column}>
                {breaking.reason}
              </Col>
              <Col span={3} className={styles.breaking__column}>
                <div
                  className={width < 768 ? styles.breaking__price : undefined}
                >
                  {breaking.price}
                </div>
              </Col>
              <Col span={5} className={styles.breaking__order}>
                <div
                  className={styles.breaking__button}
                  onClick={setModalVisible}
                >
                  Заказать
                </div>
              </Col>
            </Row>
          </div>
        )}
        {width < 768 && (
          <Row>
            <Col span={8} sm={24} xs={24} className={styles.breaking__column}>
              Причина: {breaking.reason}
            </Col>
            <Col span={3} sm={18} xs={12} className={styles.breaking__column}>
              <div className={width < 768 ? styles.breaking__price : undefined}>
                {breaking.price}
              </div>
            </Col>
            <Col span={5} sm={6} xs={12} className={styles.breaking__order}>
              <div
                className={styles.breaking__button}
                onClick={setModalVisible}
              >
                Заказать
              </div>
            </Col>
          </Row>
        )}
      </>
    );
  }
);
