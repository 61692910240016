import React, {memo} from "react";
import styles from './examples.module.less'
import {Image} from "antd";
import {ExampleModel} from "./examples.model";

interface ExamplesItemProps {
    readonly example: ExampleModel;
    readonly setModalVisible: () => void;
}

export const ExamplesItem = memo<ExamplesItemProps>(({example, setModalVisible}) => {
    return (
        <div className={styles.examples__item}>
            <Image
                preview={false}
                src={require('../../assets/images/examples/' + example.img)}
            />
            <div className={styles.examples__name}>
                {example.name}
            </div>
            <div className={styles.examples__order}>
                <div className={styles.examples__price}>
                    {example.price} &#8381;
                </div>
                <div className={styles.examples__button} onClick={setModalVisible}>Заказать</div>
            </div>
        </div>
    )
})
