import React, {
  memo,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from "react";
import styles from "./navbar.module.less";
import { Header } from "antd/es/layout/layout";
import { Typography, Menu, Image, Row, Tabs, Button } from "antd";
import classnames from "classnames";
import { useResize } from "../app/resize.hook";
import {
  CloseOutlined,
  InstagramOutlined,
  MenuOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { AnchorRef } from "../app/navigation/navigation.model";
import Icon from "../common/icon/icon.component";
import Buttons from "../common/buttons/buttons.component";

const { Link } = Typography;

interface NavbarProps {
  readonly anchors: AnchorRef[];
  readonly currentAnchor: string;
  readonly onRedirect: (name: string) => void;
  readonly setModalVisible: () => void;
}

const Navbar = memo<NavbarProps>(
  ({ currentAnchor, onRedirect, anchors, setModalVisible }) => {
    const [selectedKey, setSelectedKey] = useState<string[]>(["banner"]);

    useEffect(() => {
      setSelectedKey([currentAnchor]);
    }, [currentAnchor]);

    const width = useResize();

    const [siderCollapsed, setSiderCollapsed] = useState(true);

    const handleDocumentClick = useCallback(
      (event: MouseEvent) => {
        if ((event.target as Element).closest(`.${styles.navbar__sider}`)) {
          return;
        }
        setSiderCollapsed(!siderCollapsed);
        document.removeEventListener("click", handleDocumentClick);
      },
      [siderCollapsed, setSiderCollapsed]
    );

    useEffect(() => {
      if (siderCollapsed) {
        return;
      }
      document.addEventListener("click", handleDocumentClick);
    }, [siderCollapsed]);

    useEffect(() => {
      return () => {
        document.removeEventListener("click", handleDocumentClick);
      };
    }, []);

    const handleButtonClick = useCallback(() => {
      setModalVisible();
    }, []);

    return (
      <>
        <Header className={styles.navbar}>
          <Row
            justify="space-between"
            align="middle"
            className={styles.navbar__main}
          >
            {width < 768 &&
              (siderCollapsed ? (
                <MenuOutlined
                  className={styles.navbar__menuIcon}
                  onClick={() => {
                    setSiderCollapsed(!siderCollapsed);
                  }}
                />
              ) : (
                <CloseOutlined
                  className={styles.navbar__menuIcon}
                  onClick={() => {
                    setSiderCollapsed(!siderCollapsed);
                  }}
                />
              ))}
            <div className={styles.navbar__logoWrapper}>
              <Image
                preview={false}
                className={styles.navbar__logo}
                src={require("../../assets/images/washer-logo-mini.png")}
              />
              <div className={styles.navbar__logoText}>
                Ремонт <span>Стиральных Машин</span> в Самаре
              </div>
            </div>
            <Buttons />
          </Row>
          {width >= 768 && (
            <Row
              justify="center"
              align="middle"
              className={styles.navbar__menu}
            >
              <Tabs
                defaultActiveKey="banner"
                activeKey={currentAnchor}
                size="small"
                onTabClick={(key: string) => {
                  onRedirect(key);
                }}
              >
                {!!anchors.length &&
                  anchors.map((anchor) => (
                    // <Menu.Item className={styles.navbar__menuItem} key={anchor.name}>
                    //     <Link onClick={() => {onRedirect(anchor.name)}}>
                    //         {anchor.title}
                    //     </Link>
                    // </Menu.Item>
                    <Tabs.TabPane tab={anchor.title} key={anchor.name} />
                  ))}
              </Tabs>
            </Row>
          )}
          ;
        </Header>
        <Sider
          className={styles.navbar__sider}
          collapsed={siderCollapsed || width >= 768}
          collapsedWidth={0}
          theme="light"
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            zIndex: 10,
            right: 0,
          }}
        >
          <Menu
            className={classnames(
              styles.navbar__menu,
              styles.navbar__menu_mobile,
              { [styles.navbar__menu_indent]: width < 1200 }
            )}
            selectedKeys={selectedKey}
            multiple={false}
            selectable={false}
          >
            {!!anchors.length &&
              anchors.map((anchor) => (
                <Menu.Item
                  className={styles.navbar__menuItem_mobile}
                  key={anchor.name}
                >
                  <Link
                    onClick={() => {
                      onRedirect(anchor.name);
                    }}
                  >
                    {anchor.title}
                  </Link>
                </Menu.Item>
              ))}
          </Menu>
        </Sider>
      </>
    );
  }
);

export default Navbar;
