import React, { memo, RefObject, useEffect, useState } from "react";
import styles from "./breakings.module.less";
import { Col, Row, Collapse } from "antd";
import { BreakingItem } from "./breakings-item.component";
import { useResize } from "../app/resize.hook";
import { BREAKINGS } from "./breakings.constant";

interface BreakingsProps {
  readonly breakingsRef: RefObject<HTMLDivElement>;
  readonly setModalVisible: () => void;
}

export const Breakings = memo<BreakingsProps>(
  ({ breakingsRef, setModalVisible }) => {
    const width = useResize();

    return (
      <div className={styles.breaking} ref={breakingsRef}>
        <Row justify={"center"}>
          <Col span={18} lg={18} md={20} sm={20}>
            <h2 className={styles.breaking__title}>
              Частые поломки стиральных машин
            </h2>
            {width >= 768 && (
              <Row justify={"start"} className={styles.breaking__table}>
                <Col span={8}>
                  <h4 className={styles.breaking__table_text}>Признак</h4>
                </Col>
                <Col span={8}>
                  <h4 className={styles.breaking__table_text}>
                    Вероятная причина
                  </h4>
                </Col>
                <Col span={8}>
                  <h4
                    className={styles.breaking__table_text}
                    style={{ marginLeft: "-3px" }}
                  >
                    Стоимость ремонта
                  </h4>
                </Col>
              </Row>
            )}
            <Row
              gutter={[
                { xs: 0, sm: 0, md: 0, lg: 0 },
                { xs: 0, sm: 0, md: 8, lg: 8 },
              ]}
              justify="center"
              className={styles.breaking__content}
            >
              {width >= 768 &&
                BREAKINGS.map((item, i) => (
                  <Col key={i} span={24}>
                    <BreakingItem
                      breaking={item}
                      setModalVisible={setModalVisible}
                    />
                  </Col>
                ))}
              {width < 768 && (
                <Collapse
                  style={{ width: "100%" }}
                  expandIconPosition={"right"}
                >
                  {BREAKINGS.map((item, i) => (
                    <Collapse.Panel
                      key={i}
                      header={item.symptom}
                      style={{ background: "white" }}
                    >
                      <BreakingItem
                        breaking={item}
                        setModalVisible={setModalVisible}
                      />
                    </Collapse.Panel>
                  ))}
                </Collapse>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
);
