import styles from "./advantages.module.less";
import { Col, Row, Image } from "antd";
import React, { memo, RefObject, useState } from "react";
import { useResize } from "../app/resize.hook";

interface AdvantagesProps {
  readonly advantagesRef: RefObject<HTMLDivElement>;
}

const ADVANTAGES = [
  {
    image: "advantages_stage1.svg",
    title: "Качество",
    text: "Я ручаюсь за качество выполненной работы.",
  },
  {
    image: "advantages_stage2.svg",
    title: "Оперативность",
    text: "Я всегда стремлюсь выполнять работу как можно быстрее. Время - деньги!",
  },
  {
    image: "advantages_stage3.svg",
    title: "Цена",
    text: "Я могу похвастаться ценой ниже рыночной, при этом без потери в качестве.",
  },
];

export const Advantages = memo<AdvantagesProps>(({ advantagesRef }) => {
  const width = useResize();

  return (
    <div className={styles.advantages} ref={advantagesRef}>
      <div className={styles.advantages__banner}>
        <h2 className={styles.advantages__title}>Почему именно я?</h2>
        <Row justify="center" align="top">
          {ADVANTAGES.map(({ image, title, text }) => (
            <Col
              key={title}
              className={styles.advantages__stage}
              span={6}
              lg={6}
              md={8}
              sm={24}
              xs={24}
            >
              <Row
                gutter={[
                  { xs: 15, sm: 15, md: 0, lg: 0 },
                  { xs: 0, sm: 0, md: 0, lg: 0 },
                ]}
              >
                <Col
                  span={24}
                  lg={24}
                  md={24}
                  sm={width > 650 ? 2 : 3}
                  xs={width > 400 ? 3 : 4}
                >
                  <Image
                    preview={false}
                    src={require("../../assets/icons/" + image)}
                  />
                </Col>
                <Col span={24} lg={24} md={24} sm={20} xs={19}>
                  <h3 className={styles["advantages__stage-title"]}>{title}</h3>
                  <p className={styles["advantages__stage-text"]}>{text}</p>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
});
