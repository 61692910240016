import React, { memo, RefObject, useCallback, useState } from "react";
import styles from "./banner.module.less";
import SubmitForm from "../common/form/form.component";
import classnames from "classnames";
import { Button, Modal } from "antd";

interface BannerProps {
  readonly bannerRef?: RefObject<HTMLDivElement>;
  readonly footer?: boolean;
  readonly onSuccess: () => void;
}

const Banner = memo<BannerProps>(({ bannerRef, footer, onSuccess }) => {
  return (
    <div
      className={classnames(styles.banner, { [styles.footer]: footer })}
      id="banner"
      ref={bannerRef}
    >
      <div className={styles.banner__wrapper}>
        {!footer && (
          <>
            <div className={styles.banner__title}>
              Ремонт стиральных машин <br />
              <span style={{ color: "#F8910A" }}> на дому</span>
            </div>
            <div className={styles.banner__text}>
              Если вы обнаружили поломку, смело звоните мне.
              <br />
              Проведу диагностику и отремонтирую технику прямо у Вас дома.
              <br />
              Заказать выезд можно по телефону
              <br />
              <a
                className={styles.banner__text}
                href="tel:+7(999)4417165"
                style={{ color: "#F8910A" }}
              >
                +7(999)441-71-65
              </a>{" "}
              или через наш сайт.
            </div>
          </>
        )}
        {footer && (
          <>
            <div className={styles.banner__subtitle}>Обратная связь</div>
            <div
              className={classnames(styles.banner__text, {
                [styles.footer]: footer,
              })}
            >
              Решить проблему с Вашей стиральной машиной проще, чем Вам может
              показаться. Заполните простую форму ниже и я сам Вам перезвоню!
            </div>
          </>
        )}
        <SubmitForm onSuccess={onSuccess} />
      </div>
    </div>
  );
});

export default Banner;
