import React, {useCallback, useContext, useEffect, useState} from "react";
import _ from "lodash";

export interface ResizeContextValue {
    width: number;
}

export const ResizeContext = React.createContext<ResizeContextValue>({
    width: 0
});

export const useResizeProvider = (): number => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    const handleWindowResize = useCallback(() => {
        setWidth(window.innerWidth);
    }, [setWidth])

    const debouncedHandleResize = _.debounce(handleWindowResize, 100);

    useEffect(() => {
        window.addEventListener('resize', debouncedHandleResize);
        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        }
    }, []);

    return width;
}

export const useResize = (): number => {

    const { width } = useContext(ResizeContext);

    return width;
}


