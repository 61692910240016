import React, {memo, useEffect} from "react";
import {useDynamicSVGImport} from "./icon.hook";

interface IconProps extends React.HTMLAttributes<HTMLElement> {
    readonly name: string;
}

const Icon = memo<IconProps>(({ name, className }) => {
    const { error, loading, SvgIcon } = useDynamicSVGImport(name);
    useEffect(() => {
    }, [SvgIcon])
    return (error || loading) ? <></> : <div className={className}>{SvgIcon}</div>;
});

export default Icon;
