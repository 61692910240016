import React, {memo} from "react";
import {Button as AntButton} from "antd";
import styles from './button.module.less';
import classnames from "classnames";

interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
    readonly text: string;
    readonly size?: string;
}

const Button = memo<ButtonProps>(({ text, size, className, onClick }) => {
    return (
        <AntButton
            className={classnames(
                styles.button,
                {[styles.button_xl]: size === 'xl'},
                className
            )}
            onClick={onClick}
            shape="round"
            type="primary"
            danger
        >
            {text}
        </AntButton>
    );
});

export default Button;
