import React from "react";
import styles from "./footer.module.less";
import { useResize } from "../app/resize.hook";
import Buttons from "../common/buttons/buttons.component";
import { Col, Row } from "antd";

export const Footer = () => {
  return (
    <div className={styles.footer}>
      <Row align="middle" gutter={20}>
        <Col>
          <p>Самозанятый</p>
          <p>tros.24@yandex.ru</p>
          <p>г. Самара</p>
          <p>ИНН 246318994395</p>
        </Col>
        <Col>
          <Buttons />
        </Col>
      </Row>
    </div>
  );
};
