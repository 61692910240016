import React, { memo, RefObject } from "react";
import styles from "./about.module.less";
import { Col, Row, Image } from "antd";

interface AboutProps {
  readonly aboutRef: RefObject<HTMLDivElement>;
}

const About = memo<AboutProps>(({ aboutRef }) => {
  return (
    <div className={styles.about} ref={aboutRef}>
      <div className={styles.about__wrapper}>
        <Row gutter={20}>
          <Col span={14} xs={24} sm={22} md={14} lg={14} xl={14}>
            <h2>О себе</h2>
            <p>
              Меня зовут Александр! Я частный мастер! Опыт более 6 лет в сфере
              ремонта стиральных машин. Я всегда использую исключительно
              проверенные запчасти и инструменты, ну и, конечно же, всегда даю
              гарантии на выполненные мной работы!
            </p>
            <p>
              Ремонт стиральных машин — это не просто мой профиль, это дело,
              которое я люблю, и умею делать профессионально.
            </p>
            <p>Нет такого вопроса, который не мог бы решить.</p>
          </Col>
          <Col span={10} xs={20} sm={16} md={10} lg={10} xl={10}>
            <Row gutter={[20, 20]}>
              <Image
                preview={false}
                className={styles.about__image}
                src={require("../../assets/images/me.jpg")}
              />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default About;
