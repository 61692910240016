import {ExampleModel} from "./examples.model";

export const EXAMPLES: ExampleModel[] = [
    {
        img: 'podshipnik.jpeg',
        name: 'Замена подшипников барабана.',
        price: '3500'
    },
    {
        img: 'tena.jpeg',
        name: 'Замена тены',
        price: '1500'
    },
    {
        img: 'nasos.jpeg',
        name: 'Замена сливного насоса',
        price: '2000'
    },
    {
        img: 'engine.jpeg',
        name: 'Ремонт эл. двигателя стиральной машинки',
        price: '2500'
    },
]
