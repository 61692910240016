import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import styles from './app.module.less';
import Banner from "../banner/banner.component";
import Navbar from "../navbar/navbar.component";
import {ResizeContext, useResizeProvider} from './resize.hook';
import About from "../about/about.component";
import {Advantages} from "../advantages/advantages.component";
import {Examples} from "../examples/examples.component";
import {Breakings} from "../breakings/breakings.component";
import {Footer} from "../footer/footer.component";
import {NavigationHookValue, useNavigation} from "./navigation/navigation.hook";
import {Button, Modal} from "antd";
import SubmitForm from "../common/form/form.component";

const App: React.FC = () => {

    const width: number = useResizeProvider();

    const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false);


    const container: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const banner: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const about: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const advantages: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const examples: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const breakings: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const form: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

    const { anchors, currentAnchor, redirectTo }: NavigationHookValue = useNavigation([
        {name: 'banner', ref: banner, title: 'Главная'},
        {name: 'about', ref: about, title: 'О компании'},
        {name: 'advantages', ref: advantages, title: 'Преимущества'},
        {name: 'examples', ref: examples, title: 'Примеры работ'},
        {name: 'breakings', ref: breakings, title: 'Частые поломки'}
    ], container);

    const enableFormModel = useCallback(() => {
        setFormModalVisible(true);
    }, []);

    return (
        <div className={styles.app} ref={container}>
            <ResizeContext.Provider value={{ width }}>
                <Navbar anchors={anchors} currentAnchor={currentAnchor} onRedirect={redirectTo} setModalVisible={enableFormModel}/>
                <Banner bannerRef={banner} onSuccess={() => { setSuccessModalVisible(true) }}/>
                <About aboutRef={about} />
                <Advantages advantagesRef={advantages} />
                <Examples examplesRef={examples} setModalVisible={enableFormModel}/>
                <Breakings breakingsRef={breakings} setModalVisible={enableFormModel}/>
                <Banner footer onSuccess={() => { setSuccessModalVisible(true) }}/>
                <Footer />
            </ResizeContext.Provider>
            <Modal title="Обратная связь" visible={formModalVisible} onCancel={() => {setFormModalVisible(false)}} footer={null}>
                <SubmitForm
                    onSuccess={() => {
                        setFormModalVisible(false);
                        setSuccessModalVisible(true)
                    }}
                />
            </Modal>
            <Modal
                visible={successModalVisible}
                closable={false}
                footer={[
                    <Button
                        className={styles.modalButton}
                        type="primary"
                        danger
                        color="orange"
                        key="ok"
                        onClick={() => { setSuccessModalVisible(false) }}
                    >
                        Вернуться на страницу
                    </Button>
                ]}
            >
                <p>Спасибо за заявку!</p>
                <p>Мы свяжемся с вами в ближайшее время</p>
            </Modal>
        </div>
    );
};

export default App;
