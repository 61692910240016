import React, {memo, useCallback, useState} from "react";
import styles from './form.module.less';
import {Row, Form, Col, Input, Spin} from "antd";
import Button from "../../ui/button/button.component";
import axios from "axios";

interface FormProps {
    readonly onSuccess: () => void;
}

const URL = '/api/send';

const SubmitForm = memo<FormProps>(({ onSuccess }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false)

    const handleFinishForm = useCallback(async (values: any) => {
        setLoading(true);
        await axios.post(URL, values);
        setLoading(false);
        onSuccess();
    }, []);

    const handleClickFinishButton = useCallback(() => {
        form.submit();
    }, []);

    return (
        <Form form={form} layout="vertical" className={styles.form} onFinish={handleFinishForm}>
            {loading && <Spin />}
            {!loading && <Row gutter={[20, 10]} align="middle" justify="center">
                <Col>
                    <Form.Item
                        className={styles.form__item}
                        name="name"
                        rules={[{ required: true, message: 'Пожалуйста, введите имя' }]}
                    >
                        <Input className={styles.form__input} type="text" placeholder="Имя"/>
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        className={styles.form__item}
                        name="phone"
                        rules={[{ required: true, message: 'Пожалуйста, укажите номер телефона' }]}
                    >
                        <Input className={styles.form__input} type="phone" placeholder="Телефон"/>
                    </Form.Item>
                </Col>
                <Col>
                    <Button className={styles.form__button} size="xl" text="Отправить заявку" onClick={handleClickFinishButton}/>
                </Col>
            </Row>}
        </Form>
    );
});

export default SubmitForm;
