import {AnchorRef} from "./navigation.model";
import {RefObject, useCallback, useEffect, useState} from "react";
import _ from "lodash";

export interface NavigationHookValue {
    readonly redirectTo: (name: string) => void;
    readonly currentAnchor: string;
    readonly anchors: AnchorRef[];
}

export const useNavigation = (anchors: AnchorRef[], containerRef: RefObject<HTMLDivElement>): NavigationHookValue => {
    const [offsetTop, setOffsetTop] = useState<number>(0);
    const [currentAnchor, setCurrentAnchor] = useState<string>('');

    useEffect(() => {
    }, [offsetTop]);

    useEffect(() => {
    }, [currentAnchor]);

    const handleScroll = useCallback(() => {
        if (containerRef.current) {
            setOffsetTop(containerRef.current.scrollTop);
        }
    }, [setOffsetTop])

    const throttledHandleScroll = _.throttle(handleScroll, 100);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener('scroll', throttledHandleScroll, { passive: true });
            return () => {
                if (containerRef.current) {
                    containerRef.current.removeEventListener('scroll', throttledHandleScroll);
                }
            }
        }
    }, []);

    const redirectTo = useCallback((name: string) => {
        const ref = anchors.find(anchor => anchor.name === name);
        if (!ref) {
            return;
        }
        if ((ref.ref.current?.offsetTop || ref.ref.current?.offsetTop === 0) && containerRef.current) {
            containerRef.current.scrollTo(0, ref.ref.current?.offsetTop - 100);
        }
    }, [anchors]);
    
    useEffect(() => {
        const correctAnchors = anchors.filter(anchor => anchor.ref.current);
        correctAnchors.every((anchor: AnchorRef, index: number) => {
            switch (index) {
                case 0:
                    // @ts-ignore
                    if (offsetTop < correctAnchors[index + 1].ref.current.offsetTop - 150) {
                        setCurrentAnchor(anchor.name);
                        return false;
                    }
                    break;
                case correctAnchors.length - 1:
                    // @ts-ignore
                    if (offsetTop > anchor.ref.current.offsetTop - 150) {
                        setCurrentAnchor(anchor.name);
                        return false;
                    }
                    break;
                default:
                    if (
                        // @ts-ignore
                        offsetTop >= anchor.ref.current.offsetTop - 150 &&
                        // @ts-ignore
                        offsetTop < correctAnchors[index + 1].ref.current.offsetTop - 150
                    ) {
                        setCurrentAnchor(anchor.name);
                        return false;
                    }
            }
            return true;
        });
    }, [offsetTop]);

    return {
        anchors,
        redirectTo,
        currentAnchor
    };
}
