import {BreakingModel} from "./breakings.model";

export const BREAKINGS: BreakingModel[] = [
    {
        symptom: 'Сломалась дверца стиральной машины',
        reason: 'Крепление люка',
        price: 'от 2000'
    },
    {
        symptom: 'Не открывается стиральная машина после стирки',
        reason: 'Устройство блокировки люка',
        price: 'от 1000'
    },
    {
        symptom: 'Не включается стиральная машина',
        reason: 'Электронный модуль',
        price: 'от 3000'
    },
    {
        symptom: 'Течет стиральная машина',
        reason: 'Манжета люка или уплотнитель дверцы',
        price: 'от 2500'
    },
    {
        symptom: 'Стиральная машина не сливает воду',
        reason: 'Сливной насос',
        price: 'от 2000'
    },
    {
        symptom: 'Сломалась стиральная машина',
        reason: 'Блок кнопок',
        price: 'от 3000'
    },
    {
        symptom: 'Стиральная машина не набирает воду',
        reason: 'Сливной шланг, аквасенсор, датчик температуры',
        price: 'от 2000'
    },
    {
        symptom: 'Не крутит барабан в стиральной машине',
        reason: 'Электродвигатель',
        price: 'от 2000'
    },
    {
        symptom: 'Стиральная машина не крутит барабан, но работает',
        reason: 'Ремень привода',
        price: 'от 1000'
    },
    {
        symptom: 'Не греет стиральная машина',
        reason: 'ТЭН',
        price: 'от 1200'
    },
    {
        symptom: 'Стиральная машина сама нагревает воду',
        reason: 'Термостат',
        price: 'от 1000'
    },
    {
        symptom: 'Стиральная машина не отжимает',
        reason: 'Фильтр сливного насоса',
        price: 'от 1500'
    },
    {
        symptom: 'Шумит стиральная машина',
        reason: 'Опоры, крестовина, электрический жгут',
        price: 'от 1500'
    },
    {
        symptom: 'Стиральная машина не полоскает',
        reason: 'Требуется диагностика',
        price: 'от 800'
    },
    {
        symptom: 'Ошибка стиральной машины',
        reason: 'Электронный модуль',
        price: 'от 2000'
    },
    {
        symptom: 'Стиральная машина не стирает',
        reason: 'Устройство блокировки люка',
        price: 'от 1000'
    },
    {
        symptom: 'Течет стиральная машина снизу',
        reason: 'Засор',
        price: 'от 1000'
    },
    {
        symptom: 'Течет шланг воды в стиральной машине',
        reason: 'Заливной шланг',
        price: 'от 1000'
    },
    {
        symptom: 'Стиральная машина сливает и набирает воду',
        reason: 'Электросхема',
        price: 'от 2000'
    },
    {
        symptom: 'Стиральная машина не греет воду',
        reason: 'Термостат',
        price: 'от 1500'
    },
]
