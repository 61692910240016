import React, { memo, RefObject } from "react";
import styles from "./examples.module.less";
import { Col, Row } from "antd";
import { ExamplesItem } from "./examples-item.component";
import { useResize } from "../app/resize.hook";
import { EXAMPLES } from "./examples.constant";

interface ExamplesProps {
  readonly examplesRef: RefObject<HTMLDivElement>;
  readonly setModalVisible: () => void;
}

export const Examples = memo<ExamplesProps>(
  ({ examplesRef, setModalVisible }) => {
    const width = useResize();

    return (
      <div className={styles.examples} ref={examplesRef}>
        <Row justify={"center"}>
          <Col span={18} lg={18} md={20} sm={20}>
            <h2 className={styles.examples__title}>Примеры работ</h2>
            <div className={styles.examples__subtitle}>
              Я выполняю любые виды ремонта стиральных машин, вот примеры
              некоторых из них.
            </div>
            <Row
              gutter={[
                { xs: 25, sm: 30, md: 50, lg: 63 },
                { xs: 30, sm: 28, md: 35, lg: 43 },
              ]}
              justify="center"
            >
              {EXAMPLES.map((item, i) => (
                <Col
                  key={i}
                  span={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={width > 400 ? 22 : 24}
                >
                  <ExamplesItem
                    example={item}
                    setModalVisible={setModalVisible}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
);
