import React, {memo} from "react";
import styles from "./buttons.module.less";
import {Button} from "antd";
import {InstagramOutlined, PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";
import Icon from "../icon/icon.component";
import {useResize} from "../../app/resize.hook";

interface ButtonsProps {
}

const Buttons = memo<ButtonsProps>(function Buttons() {
    const width: number = useResize();
    return (
        <div className={styles.buttons}>
            <Button
                onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=+79994417165', '_blank');
                }}
                className={styles.buttonsButton}
                type="primary"
                shape="circle"
                icon={<WhatsAppOutlined />}
                size={width < 480 ? 'small' : 'middle'}
                danger
            />
            <Button
                onClick={() => {
                    window.open('https://www.instagram.com/refit124/', '_blank');
                }}
                className={styles.buttonsButton}
                type="primary"
                shape="circle"
                icon={<InstagramOutlined />}
                size={width < 480 ? 'small' : 'middle'}
                danger
            />
            <Button
                onClick={() => {
                    window.open('https://vk.com/remkrsk1', '_blank');
                }}
                className={styles.buttonsButton}
                type="primary"
                shape="circle"
                icon={<Icon name="vk" />}
                size={width < 480 ? 'small' : 'middle'}
                danger
            />
            {width < 768 ? (
                <Button
                    onClick={() => {
                        window.open('tel:+7(999)4417165');
                    }}
                    className={styles.buttonsButton}
                    type="primary"
                    shape="circle"
                    icon={<PhoneOutlined />}
                    danger
                    size={width < 480 ? 'small' : 'middle'}
                />
            ) : (
                <Button
                    onClick={() => {
                        window.open('tel:+7(999)4417165');
                    }}
                    className={styles.buttonsButton}
                    type="primary"
                    shape="round"
                    icon={<PhoneOutlined />}
                    danger
                >
                    +7 (999) 441-71-65
                </Button>
            )}
        </div>
    );
});

export default Buttons;
